import { lazy } from 'react'

//membership
const MembershipFeesAdd = lazy(() => import('../../views/manage-fees/add/index'))
const MembershipFeesAddForm = lazy(() => import('../../views/manage-fees/add/AddFee'))
const MembershipFeesEdit = lazy(() => import('../../views/manage-fees/edit/index'))
const MembershipFeesList = lazy(() => import('../../views/manage-fees/list/index'))
const MembershipFeesView = lazy(() => import('../../views/manage-fees/view/index'))
const UploadRenewals = lazy(() => import('../../views/manage-fees/bulk-upload'))
const PendingMembershipFeesList = lazy(() => import('../../views/manage-fees/pending-verification/Table'))
const VerifyPendingList = lazy(() => import('../../views/manage-fees/pending-verification/index'))
const MembershipRenewal = lazy(() => import('../../views/manage-fees/public-fee-renewal/index'))
const MembershipRenewalDetails = lazy(() => import('../../views/manage-fees/public-fee-renewal/AddFee'))

const MembershipFeesRoutes = [
  {
    path: '/renew-membership/renewal',
    element: <MembershipFeesAdd />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: '/renew-membership/renewal/:id/renew',
    element: <MembershipFeesAddForm />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: '/renew-membership/list/:id/edit',
    element: <MembershipFeesEdit />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: '/renew-membership/list',
    element: <MembershipFeesList />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: 'renewal/pending-verification',
    element: <PendingMembershipFeesList />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: 'renewal/pending-verification/:id/approve',
    element: <VerifyPendingList />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: '/renew-membership/bulk-upload-payments',
    element: <UploadRenewals />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: '/membership-fees/view/:id',
    element: <MembershipFeesView />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: '/membership-renewal',
    element: <MembershipRenewal />,
    meta: {
      // layout: 'vertical',
      layout: 'horizontal',
      publicRoute: true
      // menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/membership-renewal/:id/renew',
    element: <MembershipRenewalDetails />,
    meta: {
      layout: 'horizontal',
      publicRoute: true
      // menuHidden: true
      // restricted: true
    }
  }
]

export default MembershipFeesRoutes
