// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MembershipRenewalService from '@services/renew-membership'

export const getAllMembershipRenewal = createAsyncThunk('renewal/getAllMembershipRenewal', async (params, { rejectWithValue }) => {
  try {
    const response = await MembershipRenewalService.getAllMembershipRenewal(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getMembershipRenewalById = createAsyncThunk('renewal/getMembershipRenewalById', async (id) => {
  const response = await MembershipRenewalService.getMembershipRenewalById(id)
  return response
})

export const addMembershipRenewal = createAsyncThunk('renewal/addMembershipRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await MembershipRenewalService.addMembershipRenewal(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateMembershipRenewalPayment = createAsyncThunk('renewal/updateMembershipRenewalPayment', async (renewal, { rejectWithValue }) => {
  try {
    const response = await MembershipRenewalService.updateMembershipRenewalPayment(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateMembershipRenewal = createAsyncThunk('renewal/updateMembershipRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await MembershipRenewalService.updateMembershipRenewal(renewal)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteMembershipRenewal = createAsyncThunk('renewal/deleteMembershipRenewal', async (id, { dispatch, getState }) => {
  await MembershipRenewalService.deleteMembershipRenewal(id)
  await dispatch(getAllMembershipRenewal(getState()?.membershipRenewals.params))
  return id
})

export const membershipRenewalSlice = createSlice({
  name: 'membership_renewal',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMembershipRenewal: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMembershipRenewal.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMembershipRenewalById.fulfilled, (state, action) => {
        state.individualMembershipRenewal = action.payload
        state.loading = false
      })
      .addCase(getMembershipRenewalById.pending, (state, action) => {
        state.individualMembershipRenewal = action.payload
        state.loading = true
      })
      .addCase(getMembershipRenewalById.rejected, (state, action) => {
        state.individualMembershipRenewal = action.payload
        state.loading = false
      })
  }
})

export default membershipRenewalSlice.reducer
