import { lazy } from 'react'

//Donation
const AddDonation = lazy(() => import('../../views/manage-donation/add/index'))
const EditDonation = lazy(() => import('../../views/manage-donation/edit/index'))
const ListDonation = lazy(() => import('../../views/manage-donation/list/index'))
const ListPendingDonation = lazy(() => import('../../views/manage-donation/pending-verification/Table'))
const VerifyPendingList = lazy(() => import('../../views/manage-donation/pending-verification/index'))
const ViewDonation = lazy(() => import('../../views/manage-donation/view/index'))
const PublicDonation = lazy(() => import('../../views/membership/member/DonationMemberSearch'))
const PublicDonationDetails = lazy(() => import('../../views/membership/member/Donation'))
const NonMemberPayment = lazy(() => import('../../views/membership/member/NonMemberPayment'))
const UploadDonations = lazy(() => import('../../views/manage-donation/bulk-upload/index'))
const DonationPayOnline = lazy(() => import('../../views/membership/member/DonationPayOnline'))
const DonationPaymentStatus = lazy(() => import('../../views/membership/member/DonationPaymentStatus'))

const DonationRoutes = [
  {
    path: '/donation/add',
    element: <AddDonation />,
    meta: {
      layout: 'vertical',
      action: 'create',
      resource: 'Donation'
    }
  },
  {
    path: '/donation/bulk-upload-payments',
    element: <UploadDonations />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Donation'
    }
  },
  {
    path: '/donation/list/edit/:id',
    element: <EditDonation />,
    meta: {
      layout: 'vertical',
      action: 'update',
      resource: 'Donation'
    }
  },
  {
    path: '/donation/list',
    element: <ListDonation />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Donation'
    }
  },
  {
    path: '/donation/pending-verification',
    element: <ListPendingDonation />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Donation'
    }
  },
  {
    path: '/donation/pending-verification/:id/approve',
    element: <VerifyPendingList />,
    meta: {
      layout: 'vertical',
      action: 'update',
      resource: 'Donation'
    }
  },
  {
    path: '/donation/list/view/:id',
    element: <ViewDonation />,
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'Donation'
    }
  },
  {
    path: '/support',
    element: <PublicDonation />,
    meta: {
      publicRoute: true
      // menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/member/:id/donate',
    element: <PublicDonationDetails />,
    meta: {
      publicRoute: true
      // resourse: 'MemberProfile'
      // menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/non-member/donate',
    element: <NonMemberPayment />,
    meta: {
      publicRoute: true
      // resourse: 'MemberProfile'
      // menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/donate/:feeId/make-payment',
    element: <DonationPayOnline />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // restricted: true
    }
  },
  {
    path: '/donate/:feeId/payment-status',
    element: <DonationPaymentStatus />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // restricted: true
    }
  }
]

export default DonationRoutes
