// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/permissions/store'
import roles from '@src/views/apps/roles-permissions/roles/store'
import countries from '@src/views/master/country/store/index'
import membershipTypes from '@src/views/master/membership-type/store/index'
import paymentFrequencies from '@src/views/master/payment-frequency/store/index'
import members from '@src/views/membership/member/store/index'
import membershipRenewals from '@src/views/renew-membership/member/store/index'
import donations from '@src/views/manage-donation/store/index'
import membershipFees from '@src/views/manage-fees/store/index'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  countries,
  membershipTypes,
  paymentFrequencies,
  members,
  membershipRenewals,
  donations,
  membershipFees,
  roles
}

export default rootReducer
