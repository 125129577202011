import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

//country
const getCountries = async (params) => {
  const response = await api.get(`${servicePrefix}/countries`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllCountries = async () => {
  const response = await api.get(`${servicePrefix}/countries/all`)
  return response
}

const getCountryById = async (id) => {
  const response = await api.get(`${servicePrefix}/countries/${id}`)
  return response.data
}

const addCountry = async (country) => {
  const response = await api.post(`${servicePrefix}/countries`, country)
  return response.data
}

const updateCountry = async (country) => {
  const { id } = country
  const response = await api.patch(`${servicePrefix}/countries/${id}`, country)
  return response.data
}

const deleteCountry = async (id) => {
  const response = await api.delete(`${servicePrefix}/countries/${id}`)
  return response.data
}

const CountryService = {
  //country
  getCountries,
  getAllCountries,
  getCountryById,
  addCountry,
  updateCountry,
  deleteCountry
}

export default CountryService
