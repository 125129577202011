// ** Icons Import
import { Menu, ArrowRight, EyeOff, Folder, LifeBuoy, Shield, UserPlus, UserCheck, Users, User, Activity } from 'react-feather'

export default [
  {
    id: 'reports',
    title: 'Reports',
    icon: <Activity size={20} />,
    children: [
      {
        id: 'member-report',
        title: 'Member Report',
        icon: <ArrowRight size={12} />,
        navLink: '/member-report',
        resource: 'Report'
      },
      {
        id: 'fee-report',
        title: 'Fee Report',
        icon: <ArrowRight size={12} />,
        navLink: '/fee-report',
        resource: 'Report'
      },
      {
        id: 'donation-report',
        title: 'Donation Report',
        icon: <ArrowRight size={12} />,
        navLink: '/donation-report',
        resource: 'Report'
      }
    ]
  }
]
