import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
const Home = lazy(() => import('../../views/pages/home'))

const PublicPageRoutes = [
  {
    path: '/home',
    element: <Home />,
    meta: {
      publicRoute: true,
      restricted: false
      // layout: 'vertical'
    }
  }
]

export default PublicPageRoutes
