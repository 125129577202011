// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DonationService from '@services/donation'

export const getDonation = createAsyncThunk('donation/getDonation', async (params, { rejectWithValue }) => {
  try {
    const response = await DonationService.getAllDonation(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDonationById = createAsyncThunk('donation/getDonationById', async (id) => {
  const response = await DonationService.getDonationById(id)
  return response
})

export const addDonation = createAsyncThunk('donation/addDonation', async (donation, { rejectWithValue }) => {
  try {
    const response = await DonationService.addDonation(donation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDonationPublic = createAsyncThunk('donation/addDonation', async (donation, { rejectWithValue }) => {
  try {
    const response = await DonationService.addDonationPublic(donation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const paymentBulkUpload = createAsyncThunk('donation/paymentBulkUpload', async (data, { rejectWithValue }) => {
  try {
    const response = await DonationService.paymentBulkUpload(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDonation = createAsyncThunk('doantion/updateDonation', async (donation, { rejectWithValue }) => {
  try {
    const response = await DonationService.updateDonation(donation)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteDonation = createAsyncThunk('donation/deleteDonation', async (id, { dispatch, getState }) => {
  await DonationService.deleteDonation(id)
  await dispatch(getDonation(getState()?.donations.params))
  return id
})

export const donationSlice = createSlice({
  name: 'donation',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDonation: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDonation.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDonationById.fulfilled, (state, action) => {
        state.individualDonation = action.payload
        state.loading = false
      })
      .addCase(getDonationById.pending, (state, action) => {
        state.individualDonation = action.payload
        state.loading = true
      })
      .addCase(getDonationById.rejected, (state, action) => {
        state.individualDonation = action.payload
        state.loading = false
      })
  }
})

export default donationSlice.reducer
