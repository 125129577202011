import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//Membership
const getAllMembershipRenewal = async (params) => {
  const response = await api.get(`${servicePrefix}/membershipRenewal`, { params: stripEmptyObjects(params) })

  return response.data
}

const getMembershipRenewalById = async (id) => {
  const response = await api.get(`${servicePrefix}/membershipRenewal/${id}`)
  // console.log("jjj", response)
  return response.data
}

const addMembershipRenewal = async (renewal) => {
  const response = await api.post(`${servicePrefix}/membershipRenewal`, renewal)
  return response.data
}

// const updateMembershipRenewal = async (member) => {
//   const { id } = member
//   const response = await api.patch(`${servicePrefix}/member/${id}`, member)
//   console.log("data", response)
//   return response.data
// }

const updateMembershipRenewal = async (renewal) => {
  const { id } = renewal
  const response = await api.patch(`${servicePrefix}/membershipRenewal/${id}`, renewal)
  return response.data
}

const deleteMembershipRenewal = async (id) => {
  const response = await api.delete(`${servicePrefix}/membershipRenewal/${id}`)
  return response.data
}

const getMemberRenewalById = async (memberId) => {
  const response = await api.get(`${servicePrefix}/membershipRenewal/get-membership-renewal/${memberId}`)
  // console.log('service', response)
  return response.data
}

const updateMembershipRenewalPayment = async (renewal) => {
  const { id } = renewal
  const response = await api.patch(`${servicePrefix}/membershipRenewal/${id}/update-payment`, renewal)
  return response.data
}

const MembershipRenewalService = {
  //membership
  getAllMembershipRenewal,
  getMembershipRenewalById,
  addMembershipRenewal,
  updateMembershipRenewal,
  deleteMembershipRenewal,
  getMemberRenewalById,
  updateMembershipRenewalPayment
}

export default MembershipRenewalService
