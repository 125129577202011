import { lazy } from 'react'

const CertificateGenerate = lazy(() => import('../../views/member/certificate/index'))
const CertificatePrint = lazy(() => import('../../views/member/certificate/PrintCertificate'))
const MemberSearch = lazy(() => import('../../views/public/generate-certificate/index'))
const CertificateView = lazy(() => import('../../views/public/generate-certificate/PrintCertificate'))
const QrCertificateView = lazy(() => import('../../views/public/generate-certificate/QrCertificateGenerate'))
const PITExemptionCertificate = lazy(() => import('../../views/tax-exemption-pdf/index'))

//donation

const DonationSearch = lazy(() => import('../../views/public/donation-certificate/index'))
const DonationCertificate = lazy(() => import('../../views/public/donation-certificate/PrintCertificate'))
const QrDonatinoCertificateView = lazy(() => import('../../views/public/donation-certificate/QrCertificateGenerate'))

const CertificateRoutes = [
  {
    path: '/generate-certificate/:id',
    element: <CertificateGenerate />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  },
  {
    path: '/generate-certificate/print',
    element: <CertificatePrint />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  },
  {
    path: '/generate-certificate/member-search',
    element: <MemberSearch />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true,
      // menuHidden: true
      restricted: false
    }
  },
  {
    path: '/generate-certificate/view-certificate',
    element: <CertificateView />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/generate-certificate/view-certificate/:id/:year',
    element: <QrCertificateView />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/pit-exemption-certificate',
    element: <PITExemptionCertificate />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/generate-certificate/donation-search',
    element: <DonationSearch />,
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/generate-certificate/view-donation-certificate',
    element: <DonationCertificate />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      menuHidden: true
    }
  },
  {
    path: '/generate-certificate/view-donation-certificate/:cid/:year',
    element: <QrDonatinoCertificateView />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      menuHidden: true
      // restricted: true
    }
  }
]

export default CertificateRoutes
