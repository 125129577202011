import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getRoles = async (params) => {
  // const response = await api.get(`${servicePrefix}/roles`, { params })

  // return response.data

  const response = await api.get(`${servicePrefix}/roles`, { params: stripEmptyObjects(params) })

  return response.data
}

const getAllRoles = async () => {
  const response = await api.get(`${servicePrefix}/roles?takeAll=true`)
  return response.data
}

const getRole = async (id) => {
  const response = await api.get(`${servicePrefix}/roles/${id}`)
  return response.data
}

const addRole = async (role) => {
  const response = await api.post(`${servicePrefix}/roles`, role)
  return response.data
}

const updateRole = async (id, role) => {
  const response = await api.patch(`${servicePrefix}/roles/${id}`, stripEmptyObjects(role))
  return response.data
}

const getUsersByRole = async (id) => {
  const response = await api.get(`${servicePrefix}/roles/${id}/users`)
  return response.data
}

const RoleService = {
  getRoles,
  getAllRoles,
  getRole,
  addRole,
  updateRole,
  getUsersByRole
}

export default RoleService
