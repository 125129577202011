// ** Icons Import
import { FileText, FolderPlus, RefreshCcw } from 'react-feather'

export default [
  {
    id: 'membership-details',
    title: 'Membership Details',
    icon: <FileText size={20} />,
    navLink: '/membership-details/view',
    resource: 'MemberProfile'
  },
  {
    id: 'my-fees',
    title: 'Renewal Payments',
    icon: <RefreshCcw size={20} />,
    navLink: '/membership-renewals',
    resource: 'MemberProfile'
  },
  {
    id: 'my-donations',
    title: 'My Donations',
    icon: <FolderPlus size={20} />,
    navLink: '/member-donations',
    resource: 'MemberProfile'
  }
  // {
  //   id: 'renew-your-memnbership',
  //   title: 'Renew Your Membership',
  //   icon: <FolderPlus size={20} />,
  //   navLink: '/membership-details/renew-membership-fee',
  //   resource: 'MemberProfile'
  // }
  // {
  //   id: 'make-donation',
  //   title: 'Make Donation',
  //   icon: <FolderPlus size={20} />,
  //   navLink: '/membership-details/make-donation',
  //   resource: 'MemberProfile'
  // },
  // {
  //   id: 'membership-details-',
  //   title: 'Edit Your Details',
  //   icon: <FileText size={20} />,
  //   navLink: '/membership-details/edit',
  //   resource: 'MemberProfile'
  // }
]
