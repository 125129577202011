import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//dzongkhag
const getFees = async (params) => {
  const response = await api.get(`${servicePrefix}/membershipFee`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllFees = async () => {
  const response = await api.get(`${servicePrefix}/membershipFee/all`)
  return response.data
}
const getAllPendingFees = async () => {
  const response = await api.get(`${servicePrefix}/membershipFee/pending-list`)
  return response.data
}

const getFeesById = async (id) => {
  const response = await api.get(`${servicePrefix}/membershipFee/${id}`)
  return response.data
}

const getMemberFeeById = async (memberId) => {
  const response = await api.get(`${servicePrefix}/membershipFee/get-membership-fee/${memberId}`)
  // console.log('service', response)
  return response.data
}

const addFees = async (Fees) => {
  const response = await api.post(`${servicePrefix}/membershipFee/`, Fees)
  return response.data
}

const updateFees = async (Fees) => {
  const { id } = Fees
  const response = await api.patch(`${servicePrefix}/membershipFee/${id}`, Fees)
  return response.data
}

const addFeeOnRegistration = async (data) => {
  const response = await api.post(`${servicePrefix}/membershipFee/create-membership-fee-on-registration`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const deleteFees = async (id) => {
  const response = await api.delete(`${servicePrefix}/membershipFee/${id}`)
  return response.data
}

const paymentBulkUpload = async (data) => {
  const response = await api.post(`${servicePrefix}/membershipFee/bulk-upload-payments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const FeesService = {
  //membership
  getFees,
  getAllFees,
  getAllPendingFees,
  getFeesById,
  getMemberFeeById,
  paymentBulkUpload,
  addFees,
  updateFees,
  addFeeOnRegistration,
  deleteFees
}

export default FeesService
