import { lazy } from 'react'

const MemberReport = lazy(() => import('../../views/report/member/index'))
const FeeReport = lazy(() => import('../../views/report/fee/index'))
const DonationReport = lazy(() => import('../../views/report/donation/index'))

const reportRoutes = [
  {
    path: '/member-report',
    element: <MemberReport />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Report'
    }
  },
  {
    path: '/fee-report',
    element: <FeeReport />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Report'
    }
  },
  {
    path: '/donation-report',
    element: <DonationReport />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Report'
    }
  }
]

export default reportRoutes
