// ** Icons Import
import { Menu, ArrowRight, EyeOff, Folder, LifeBuoy, Shield, User } from 'react-feather'

export default [
  {
    id: 'masters',
    title: 'Common Data',
    icon: <Folder size={20} />,
    children: [
      {
        id: 'country',
        title: 'Country',
        icon: <ArrowRight size={12} />,
        navLink: '/country',
        resource: 'Master'
      },
      {
        id: 'paymentFrequency',
        title: 'Payment Frequency',
        icon: <ArrowRight size={12} />,
        navLink: '/payment-frequency',
        resource: 'Master'
      },
      // {
      //   id: "paymentMethod",
      //   title: "Payment Method",
      //   icon: <ArrowRight size={12} />,
      //   navLink: "/payment-method"
      // },

      {
        id: 'membership-type',
        title: 'Membership Type',
        icon: <ArrowRight size={12} />,
        navLink: '/membership-type',
        resource: 'Master'
      }
    ]
  }
]
