import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getUsers = async (params) => {
  const response = await api.get(`${servicePrefix}/users`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllUsers = async () => {
  const response = await api.get(`${servicePrefix}/users/all`)
  return response.data
}

const getUsersWithRoles = async (params) => {
  const response = await api.get(`${servicePrefix}/users/users-with-roles`, { params: stripEmptyObjects(params) })
  return response.data
}

const getUser = async (id) => {
  const response = await api.get(`${servicePrefix}/users/${id}`)
  return response.data
}

const addUser = async (user) => {
  const response = await api.post(`${servicePrefix}/users`, user)
  return response.data
}

const userPasswordReset = async (password) => {
  const { id } = password
  const response = await api.post(`${servicePrefix}/users/${id}/change-password`, password)
  return response.data
}

const passwordReset = async (password) => {
  // const { id } = password
  const response = await api.post(`${servicePrefix}/auth/password-reset`, password)
  return response.data
}

const updateUser = async (id, user) => {
  const response = await api.patch(`${servicePrefix}/users/${id}`, user)
  return response.data
}

const deleteUser = async (id) => {
  const response = await api.delete(`${servicePrefix}/users/${id}`)
  return response.data
}

const UserService = {
  getUsers,
  getAllUsers,
  getUser,
  addUser,
  getUsersWithRoles,
  updateUser,
  userPasswordReset,
  passwordReset,
  deleteUser
}

export default UserService
