// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
import UserService from '@services/user'

export const getUsers = createAsyncThunk('appUsers/getData', async (params) => {
  const response = await UserService.getUsers(params)
  return {
    params,
    data: response.data,
    totalItems: response.meta.itemCount
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await UserService.getUser(id)

  return response
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user) => {
  const response = await UserService.addUser(user)
  return response
})

export const userPasswordReset = createAsyncThunk('appUsers/userPasswordReset', async (password, { rejectWithValue }) => {
  try {
    const response = await UserService.userPasswordReset(password)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const passwordReset = createAsyncThunk('appUsers/passwordReset', async (password, { rejectWithValue }) => {
  try {
    const response = await UserService.passwordReset(password)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { rejectWithValue }) => {
  try {
    const response = await UserService.updateUser(user.id, user)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await UserService.deleteUser(id)
  await dispatch(getUsers(getState()?.users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allUsers: [],
    selectedUser: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalItems
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = false
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true
      })
  }
})

export default appUsersSlice.reducer
