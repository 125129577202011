import { lazy } from 'react'

const DashboardAnalytics = lazy(() => import('../../views/dashboard/analytics'))

const DashboardRoutes = [
  {
    path: '/dashboard',
    element: <DashboardAnalytics />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Dashboard'
      // restricted: true
    }
  }
]

export default DashboardRoutes
