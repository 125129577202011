// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CountryService from '@services/country'

export const getCountry = createAsyncThunk('country/getCountry', async (params, { rejectWithValue }) => {
  try {
    const response = await CountryService.getCountries(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllCountries = createAsyncThunk('country/getAllCountries', async () => {
  const response = await CountryService.getAllCountries()
  return {
    allData: response.data
  }
})

export const getCountryById = createAsyncThunk('country/getCountryById', async (id) => {
  const response = await CountryService.getCountryById(id)
  return response
})

export const addCountry = createAsyncThunk('country/addCountry', async (country, { rejectWithValue }) => {
  try {
    const response = await CountryService.addCountry(country)
    return response
  } catch (err) {
    console.log(err.response.data)
    return rejectWithValue(err.response.data)
  }
})

export const updateCountry = createAsyncThunk('country/updateCountry', async (country, { rejectWithValue }) => {
  try {
    const response = await CountryService.updateCountry(country)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteCountry = createAsyncThunk('country/deleteCountry', async (id, { dispatch, getState }) => {
  await CountryService.deleteCountry(id)
  await dispatch(getCountry(getState()?.countries.params))
  return id
})

export const countrySlice = createSlice({
  name: 'country',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allCountries: [],
    individualCountry: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountry.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCountryById.fulfilled, (state, action) => {
        state.individualCountry = action.payload
        state.loading = false
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.allCountries = action.payload.allCountries
      })
      .addCase(getCountryById.pending, (state, action) => {
        state.individualCountry = action.payload
        state.loading = true
      })
      .addCase(getCountryById.rejected, (state, action) => {
        state.individualCountry = action.payload
        state.loading = false
      })
  }
})

export default countrySlice.reducer
