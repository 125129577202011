// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'
import CartDropdown from './CartDropdown'
import UserDropdown from './UserDropdown'
import NavbarSearch from './NavbarSearch'
import NotificationDropdown from './NotificationDropdown'
import LoginRegister from './LoginRegister'

import { useEffect, useState } from 'react'

// ** Third Party Components
// import { Sun, Moon } from 'react-feather'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Reactstrap Imports
// import { NavItem, NavLink } from 'reactstrap'

const NavbarUser = () => {
  // ** Props
  // const { skin, setSkin } = props

  // ** State
  const [userData, setUserData] = useState(null)

  // ** Function to toggle Theme (Light/Dark)
  // const ThemeToggler = () => {
  //   if (skin === 'dark') {
  //     return <Sun className="ficon" onClick={() => setSkin('light')} />
  //   } else {
  //     return <Moon className="ficon" onClick={() => setSkin('dark')} />
  //   }
  // }

  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  return (
    <>
      {userData && (
        <ul className="nav navbar-nav align-items-center ms-auto">
          {/* <IntlDropdown /> */}
          {/* <NavItem className="d-none d-lg-block">
        <NavLink className="nav-link-style">
          <ThemeToggler />
        </NavLink>
      </NavItem> */}
          {/* <NavbarSearch /> */}
          {/* <CartDropdown /> */}
          {/* <NotificationDropdown /> */}
          {userData ? <UserDropdown /> : <></>}
        </ul>
      )}
    </>
  )
}
export default NavbarUser
