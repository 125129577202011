import { Menu, Circle, EyeOff, Folder, LifeBuoy, Shield, UserPlus, UserCheck, Users, User, ArrowRight, Check } from 'react-feather'
import { AiOutlineReload } from 'react-icons/ai'

export default [
  {
    id: 'manage-membership-renewal',
    title: 'Renewal',
    icon: <AiOutlineReload size={20} />,
    children: [
      {
        id: 'renew-membership-add',
        title: 'Renew Membership',
        icon: <ArrowRight size={12} />,
        navLink: '/renew-membership/renewal',
        resource: 'Member'
      },
      {
        id: 'import-renewal',
        title: 'Import Renewal',
        icon: <ArrowRight size={12} />,
        navLink: '/renew-membership/bulk-upload-payments',
        resource: 'Member'
      },
      {
        id: 'renew-membership',
        title: 'Renewal List',
        icon: <ArrowRight size={12} />,
        navLink: '/renew-membership/list',
        resource: 'Member'
      }
      // {
      //   id: 'pending-membership',
      //   title: 'Verify Payment',
      //   icon: <ArrowRight size={12} />,
      //   navLink: 'renew/pending-verification',
      //   resource: 'Member'
      // }
    ]
  },
  {
    id: 'pending-membership',
    title: 'Verification',
    icon: <Check size={12} />,
    navLink: 'renewal/pending-verification',
    resource: 'Member'
  }
]
