// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MembershipService from '@services/membership'
import DonationService from '@services/donation'

export const getMember = createAsyncThunk('member/getMember', async (params, { rejectWithValue }) => {
  try {
    const response = await MembershipService.getAllMember(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const allMember = createAsyncThunk('member/allMember', async () => {
  const response = await MembershipService.allMember()
  return response
})

export const getMemberById = createAsyncThunk('member/getMemberById', async (id) => {
  const response = await MembershipService.getMemberById(id)
  return response
})

export const addMember = createAsyncThunk('member/addMember', async (member, { rejectWithValue }) => {
  try {
    const response = await MembershipService.addMember(member)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const registerMember = createAsyncThunk('member/register', async (member, { rejectWithValue }) => {
  try {
    const response = await MembershipService.registerMember(member)
    console.log(response)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addPublicDonation = createAsyncThunk('donation/addPublicDonation', async (donation, { rejectWithValue }) => {
  try {
    const response = await DonationService.addPublicDonation(donation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const updateMember = createAsyncThunk("member/updateMember", async (member, { rejectWithValue }) => {
//   try {
//     const response = await MembershipService.updateMember(member)
//     console.log("member", response)
//     return response
//   } catch (err) {
//     console.log("error", err)
//     rejectWithValue(err.response.data)
//   }
// })

export const memberBulkUpload = createAsyncThunk('member/memberBulkUpload', async (data, { rejectWithValue }) => {
  try {
    const response = await MembershipService.memberBulkUpload(data)
    console.log('what', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateMember = createAsyncThunk('member/updateMember', async (member, { rejectWithValue }) => {
  try {
    const response = await MembershipService.updateMember(member)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteMember = createAsyncThunk('member/deleteMember', async (id, { dispatch, getState }) => {
  await MembershipService.deleteMember(id)
  await dispatch(getMember(getState()?.members?.params))
  return id
})

export const memberSlice = createSlice({
  name: 'members',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMember: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMember.fulfilled, (state, action) => {
        // console.log("store", action.payload)
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMemberById.fulfilled, (state, action) => {
        state.individualMember = action.payload
        state.loading = false
      })
      .addCase(getMemberById.pending, (state, action) => {
        state.individualMember = action.payload
        state.loading = true
      })
      .addCase(getMemberById.rejected, (state, action) => {
        state.individualMember = action.payload
        state.loading = false
      })
  }
})

export default memberSlice.reducer
