import axios from 'axios'
import jwt from '@src/auth/jwt/useJwt'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})

instance.interceptors.request.use(
  async (config) => {
    // ** Get token from localStorage
    const accessToken = JSON.parse(jwt.getToken())

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `${jwt.jwtConfig.tokenType} ${accessToken}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export const handleError = ({ message, data, status }) => {
  return Promise.reject({ message, data, status })
}

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // ** const { config, response: { status } } = error
    const { config, response } = error
    const originalRequest = config

    // ** if (status === 401) {
    if (response && response.status === 401) {
      if (!jwt.isAlreadyFetchingAccessToken) {
        jwt.isAlreadyFetchingAccessToken = true
        jwt.refreshToken().then((r) => {
          jwt.isAlreadyFetchingAccessToken = false

          // ** Update accessToken in localStorage
          jwt.setToken(r.data.accessToken)
          jwt.setRefreshToken(r.data.refreshToken)

          jwt.onAccessTokenFetched(r.data.accessToken)
        })
      }
      const retryOriginalRequest = new Promise((resolve) => {
        jwt.addSubscriber((accessToken) => {
          // ** Make sure to assign accessToken according to your response.
          // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          // ** Change Authorization header
          originalRequest.headers.Authorization = `${jwt.jwtConfig.tokenType} ${accessToken}`
          resolve(jwt.axios(originalRequest))
        })
      })
      return retryOriginalRequest
    }
    return Promise.reject(error)
  }
)

export default instance
