// ** Icons Import
import { Menu, ArrowRight, EyeOff, Folder, LifeBuoy, Shield, UserPlus, UserCheck, Users, User } from 'react-feather'

export default [
  {
    id: 'manage-membership',
    title: 'Members',
    icon: <Users size={20} />,
    children: [
      {
        id: 'add-member',
        title: 'Add Member',
        icon: <ArrowRight size={12} />,
        navLink: '/membership/add',
        resource: 'Member'
      },
      {
        id: 'import-member',
        title: 'Import Member',
        icon: <ArrowRight size={12} />,
        navLink: '/members/bulk-upload-members',
        resource: 'Member'
      },
      {
        id: 'member-list',
        title: 'Member List',
        icon: <ArrowRight size={12} />,
        navLink: '/membership-list',
        resource: 'Member'
      }
      // {
      //   id: 'pending-membership',
      //   title: 'Pending Verification',
      //   icon: <ArrowRight size={12} />,
      //   navLink: 'renew/pending-verification',
      //   resource: 'Member'
      // },
      // {
      //   id: 'renew-membership',
      //   title: 'Renewal List',
      //   icon: <ArrowRight size={12} />,
      //   navLink: '/renew-membership/list',
      //   resource: 'Member'
      // },
      // {
      //   id: 'renew-membership-add',
      //   title: 'Renew Membership',
      //   icon: <ArrowRight size={12} />,
      //   navLink: '/renew-membership/renewal',
      //   resource: 'Member'
      // },
      // {
      //   id: 'import-renewal',
      //   title: 'Import Renewal',
      //   icon: <ArrowRight size={12} />,
      //   navLink: '/renew-membership/bulk-upload-payments',
      //   resource: 'Member'
      // }
    ]
  }
]
