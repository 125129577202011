import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

//country
const getMembershipTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/membership-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllMembershipTypes = async () => {
  const response = await api.get(`${servicePrefix}/membership-types/all`)
  return response
}

const getMembershipTypeById = async (id) => {
  const response = await api.get(`${servicePrefix}/membership-types/${id}`)
  return response.data
}

const addMembershipTypes = async (country) => {
  const response = await api.post(`${servicePrefix}/membership-types`, country)
  return response.data
}

const updateMembershipTypes = async (country) => {
  const { id } = country
  const response = await api.patch(`${servicePrefix}/membership-types/${id}`, country)
  return response.data
}

const deleteMembershipTypes = async (id) => {
  const response = await api.delete(`${servicePrefix}/membership-types/${id}`)
  return response.data
}

const MembershipTypeService = {
  getMembershipTypes,
  getAllMembershipTypes,
  getMembershipTypeById,
  addMembershipTypes,
  updateMembershipTypes,
  deleteMembershipTypes
}

export default MembershipTypeService
