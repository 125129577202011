import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import { LogIn, LogOut } from 'react-feather'

// ** Horizontal Menu Components
import HorizontalNavMenuItems from './HorizontalNavMenuItems'
import { useEffect, useState } from 'react'

import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

const HorizontalMenu = ({ menuData }) => {
  const [userData, setUserData] = useState(null)

  const dispatch = useDispatch()
  // const navigate = useNavigate()

  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])

  return (
    <div className="navbar-container main-menu-content">
      <ul className="nav navbar-nav" id="main-menu-navigation">
        <HorizontalNavMenuItems submenu={false} items={menuData} />

        {userData ? (
          <li className={classnames('nav-item')}>
            <NavLink to="/login" className={classnames('nav-link')} onClick={() => dispatch(handleLogout())}>
              <LogOut />
              <span>Logout</span>
            </NavLink>
          </li>
        ) : (
          <li className={classnames('nav-item')}>
            <NavLink to="/login" className={classnames('nav-link')}>
              <LogIn />
              <span>Login</span>
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  )
}

export default HorizontalMenu
