import api from '@services'

import { stripEmptyObjects } from '@utils'
const servicePrefix = process.env.REACT_APP_API_BASE_URL

//country
const getPaymentFrequencies = async (params) => {
  const response = await api.get(`${servicePrefix}/payment-frequencies`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllPaymentFrequencies = async () => {
  const response = await api.get(`${servicePrefix}/payment-frequencies/all`)
  return response
}

const getPaymentFrequencyById = async (id) => {
  const response = await api.get(`${servicePrefix}/payment-frequencies/${id}`)
  return response.data
}

const addPaymentFrequencies = async (country) => {
  const response = await api.post(`${servicePrefix}/payment-frequencies`, country)
  return response.data
}

const updatePaymentFrequencies = async (country) => {
  const { id } = country
  const response = await api.patch(`${servicePrefix}/payment-frequencies/${id}`, country)
  return response.data
}

const deletePaymentFrequencies = async (id) => {
  const response = await api.delete(`${servicePrefix}/payment-frequencies/${id}`)
  return response.data
}

const PaymentFrequencyService = {
  getPaymentFrequencies,
  getAllPaymentFrequencies,
  getPaymentFrequencyById,
  addPaymentFrequencies,
  updatePaymentFrequencies,
  deletePaymentFrequencies
}

export default PaymentFrequencyService
