// ** Icons Import
import { Home, Activity, ShoppingCart, CheckSquare, LogIn, ArrowDown, Repeat, FileText, ThumbsUp, UserPlus, RefreshCcw, Heart, ArrowRight } from 'react-feather'
import { AiOutlineQuestion } from 'react-icons/ai'

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home />,
    navLink: '/home',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    id: 'register',
    title: 'New member registration',
    icon: <UserPlus />,
    navLink: '/register',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    id: 'renew',
    title: 'Renew Membership',
    icon: <RefreshCcw />,
    navLink: '/renew',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    id: 'donate',
    title: 'Donate',
    icon: <Heart />,
    navLink: '/support',
    meta: {
      publicRoute: true,
      restricted: false
    }
  },
  {
    id: 'generate-tax-exemption',
    title: 'Generate Receipt',
    icon: <FileText />,
    meta: {
      publicRoute: true,
      restricted: false
    },
    children: [
      {
        id: 'generate-tax-exemption-certificate',
        title: 'Generate Member Receipt',
        icon: <ArrowRight />,
        navLink: '/generate-certificate/member-search',
        meta: {
          publicRoute: true,
          restricted: false
        }
      },
      {
        id: 'donation-receipt',
        title: 'Generate Donation Receipt',
        icon: <ArrowRight />,
        navLink: '/generate-certificate/donation-search',
        meta: {
          publicRoute: true
        }
      }
    ]
  },

  {
    id: 'faq',
    title: 'FAQ',
    icon: <AiOutlineQuestion />,
    navLink: 'faq',
    meta: {
      publicRoute: true
    }
  },
  {
    id: 'login',
    title: 'Login',
    icon: <FileText />,
    navLink: '/login',
    meta: {
      publicRoute: true,
      restricted: false
    }
  }
]
