// ** React Imports
import { Fragment } from 'react'

// ** Custom Components
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'

import KathokLogo from '@src/assets/images/logo/logo-kathok.png'

const ThemeNavbar = (props) => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center justify-content-between">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
        <div className="d-flex justify-content-between">
          <img
            src={KathokLogo}
            style={{
              height: '30px',
              marginTop: '5px',
              marginLeft: '10px'
            }}
          />
          <h6
            style={{
              // height: '30px',
              marginTop: '10px',
              color: 'white',
              marginLeft: '10px'
            }}
          >
            Kathok Membership
          </h6>
        </div>
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
