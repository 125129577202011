// ** Navigation imports
// import apps from './apps'
// import pages from './pages'
// import forms from './forms'
// import tables from './tables'
// import others from './others'
// import charts from './charts'

import dashboards from './dashboards'
import masters from './masters'
import membership from './membership'
// import memberStats from './memberStats'
import reports from './reports'
// import memberRenew from './memberRenew'
import donation from './donation'
import membershipFees from './membershipFees'
import admin from './admin'
import member from './member'
import content from './content'
// import paymentMethod from "./paymentMethod"

// import uiElements from './ui-elements'

// ** Merge & Export
export default [...dashboards, ...member, ...admin, ...membership, ...membershipFees, ...donation, ...masters, ...reports, ...content]
