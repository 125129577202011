import { lazy } from 'react'

//Membership details
const MembershipDetails = lazy(() => import('../../views/member/member-details/index'))
const MembershipDetailEdit = lazy(() => import('../../views/member/member-details/edit/index'))
const MembershipRenewals = lazy(() => import('../../views/member/renewals/index'))
const Donations = lazy(() => import('../../views/member/donations/index'))
const MakeDonation = lazy(() => import('../../views/member/donations/make-donation/index'))
// const DonationReferenceDetails = lazy(() => import('../../views/member/donations/make-donation/SelectInputOrUpload'))
const FeeRenewal = lazy(() => import('../../views/member/renewals/fee-renewal/index'))
// const FeeReferenceDetails = lazy(() => import('../../views/member/renewals/fee-renewal/SelectInputOrUpload'))

const MemberProfileRoutes = [
  {
    path: '/membership-details/view',
    element: <MembershipDetails />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  },
  {
    path: '/membership-renewals',
    element: <MembershipRenewals />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  },
  {
    path: '/member-donations',
    element: <Donations />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  },
  {
    path: '/membership-details/:id/make-donation',
    element: <MakeDonation />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  },
  // {
  //   path: '/member-donations/reference-details',
  //   element: <DonationReferenceDetails />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'MemberProfile'
  //   }
  // },
  {
    path: '/membership-details/:id/renew-membership-fee',
    element: <FeeRenewal />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  },
  // {
  //   path: '/membership-details/reference-details',
  //   element: <FeeReferenceDetails />,
  //   meta: {
  //     action: 'manage',
  //     resource: 'MemberProfile'
  //   }
  // },
  {
    path: '/membership-details/edit',
    element: <MembershipDetailEdit />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'MemberProfile'
    }
  }
]

export default MemberProfileRoutes
