// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MembershipTypeService from '@services/membership-type'

export const getMembershipTypes = createAsyncThunk('membershipType/getMembershipTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await MembershipTypeService.getMembershipTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllMembershipTypes = createAsyncThunk('membershipType/getAllMembershipTypes', async () => {
  const response = await MembershipTypeService.getAllMembershipTypes()
  return {
    allData: response.data
  }
})

export const getMembershipTypeById = createAsyncThunk('membershipType/getMembershipTypeById', async (id) => {
  const response = await MembershipTypeService.getMembershipTypeById(id)
  console.log(response)
  return response
})

export const addMembershipType = createAsyncThunk('membershipType/addMembershipType', async (membershipType, { rejectWithValue }) => {
  console.log(membershipType)

  try {
    const response = await MembershipTypeService.addMembershipTypes(membershipType)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateMembershipType = createAsyncThunk('membershipType/updateMembershipType', async (membershipType, { rejectWithValue }) => {
  try {
    const response = await MembershipTypeService.updateMembershipTypes(membershipType)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteMembershipType = createAsyncThunk('membershipType/deleteMembershipType', async (id, { dispatch, getState }) => {
  await MembershipTypeService.deleteMembershipTypes(id)
  await dispatch(getMembershipTypes(getState()?.membershipTypes.params))
  return id
})

export const membershipTypeSlice = createSlice({
  name: 'membershipType',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allMembershipTypes: [],
    individualMembershipType: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMembershipTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMembershipTypeById.fulfilled, (state, action) => {
        state.individualMembershipType = action.payload
        state.loading = false
      })
      .addCase(getAllMembershipTypes.fulfilled, (state, action) => {
        state.allMembershipTypes = action.payload.allMembershipTypes
      })
      .addCase(getMembershipTypeById.pending, (state, action) => {
        state.individualMembershipType = action.payload
        state.loading = true
      })
      .addCase(getMembershipTypeById.rejected, (state, action) => {
        state.individualMembershipType = action.payload
        state.loading = false
      })
  }
})

export default membershipTypeSlice.reducer
