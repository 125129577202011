// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MembershipFeeService from '@services/membership-fees'

// export const getFees = createAsyncThunk('fees/getFees', async (params) => {
//   const response = await MembershipFeeService.getFees(params)
//   return {
//     params,
//     data: response.data,
//     totalItems: response.itemCount
//   }
// })

export const getFees = createAsyncThunk('fees/getFees', async (params) => {
  try {
    const response = await MembershipFeeService.getFees(params)
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const getFees = createAsyncThunk('fees/getFees', async (params, { rejectWithValue }) => {
//   try {
//     const response = await MembershipFeeService.getAllFees(params)
//     console.log('response', response)
//     return { params, data: response.data, totalPages: response.meta.itemCount }
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

// export const getAllPendingFees = createAsyncThunk('member/getAllPendingFees', async (rejectWithValue) => {
//   try {
//     const response = await MembershipFeeService.getAllPendingFees()
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

export const paymentBulkUpload = createAsyncThunk('member/paymentBulkUpload', async (data, { rejectWithValue }) => {
  try {
    const response = await MembershipFeeService.paymentBulkUpload(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getFeesById = createAsyncThunk('fees/getFeesById', async (id) => {
  const response = await MembershipFeeService.getFeesById(id)
  return response
})

export const addFees = createAsyncThunk('fees/addFees', async (fees, { rejectWithValue }) => {
  try {
    const response = await MembershipFeeService.addFees(fees)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateFees = createAsyncThunk('fees/updateFees', async (fees, { rejectWithValue }) => {
  try {
    const response = await MembershipFeeService.updateFees(fees)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteFees = createAsyncThunk('fees/deleteFees', async (id, { dispatch, getState }) => {
  await MembershipFeeService.deleteFees(id)
  await dispatch(getFees(getState()?.membershipFees.params))
  return id
})

export const membershipFeeSlice = createSlice({
  name: 'membershipFees',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualFees: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFees.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getFeesById.fulfilled, (state, action) => {
        state.individualFees = action.payload
        state.loading = false
      })
      .addCase(getFeesById.pending, (state, action) => {
        state.individualFees = action.payload
        state.loading = true
      })
      .addCase(getFeesById.rejected, (state, action) => {
        state.individualFees = action.payload
        state.loading = false
      })
  }
})

export default membershipFeeSlice.reducer
