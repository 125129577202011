// ** Icons Import
import { Menu, ArrowRight, EyeOff, Folder, LifeBuoy, Shield, UserPlus, UserCheck, Users, User, DollarSign } from 'react-feather'

export default [
  {
    id: 'manage-donation',
    title: 'Manage Donation',
    icon: <DollarSign size={20} />,
    children: [
      {
        id: 'donation-add',
        title: 'Add Donation',
        icon: <ArrowRight size={20} />,
        navLink: '/donation/add',
        resource: 'Donation'
      },
      {
        id: 'import-donation',
        title: 'Import Donation',
        icon: <ArrowRight size={20} />,
        navLink: '/donation/bulk-upload-payments',
        resource: 'Donation'
      },
      {
        id: 'donation-list',
        title: 'Donation List',
        icon: <ArrowRight size={20} />,
        navLink: '/donation/list',
        resource: 'Donation'
      },
      {
        id: 'verify-donation',
        title: 'Verify Donation',
        icon: <ArrowRight size={20} />,
        navLink: '/donation/pending-verification',
        resource: 'Donation'
      }
    ]
  }
]
