import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//Membership
const getAllMember = async (params) => {
  const response = await api.get(`${servicePrefix}/member`, { params: stripEmptyObjects(params) })

  return response.data
}

const getMemberById = async (id) => {
  const response = await api.get(`${servicePrefix}/member/${id}`)
  // console.log("jjj", response)
  return response.data
}

const addMember = async (member) => {
  const response = await api.post(`${servicePrefix}/member`, member)
  return response.data
}

const allMembers = async () => {
  const response = await api.get(`${servicePrefix}/member/all`)
  return response.data
}

const getNewMemberCode = async () => {
  const response = await api.get(`${servicePrefix}/member/get-new-member-code`)
  return response.data
}

const memberBulkUpload = async (data) => {
  const response = await api.post(`${servicePrefix}/member/bulk-upload-members`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  console.log('response', response)
  return response.data
}

const registerMember = async (member) => {
  const response = await api.post(`${servicePrefix}/member/register`, member)
  return response.data
}

const updateMember = async (member) => {
  const { id } = member
  const response = await api.patch(`${servicePrefix}/member/${id}`, member)
  return response.data
}

const deleteMember = async (id) => {
  const response = await api.delete(`${servicePrefix}/member/${id}`)
  return response.data
}
const searchMember = async (search) => {
  const response = await api.get(`${servicePrefix}/member/member-individual-search/${encodeURIComponent(search)}`)
  return response.data
}

const totalMembersByMembership = async () => {
  const response = await api.get(`${servicePrefix}/member/get-total-member-by-membershipType`)
  return response.data
}

const totalMemberPending = async () => {
  const response = await api.get(`${servicePrefix}/member/pending-list-total`)
  return response.data
}

const totalMemberActive = async () => {
  const response = await api.get(`${servicePrefix}/member/active-list-total`)
  return response.data
}

const getTotalMemberByCountry = async () => {
  const response = await api.get(`${servicePrefix}/member/get-total-member-by-country`)
  return response.data
}

const getMemberTotal = async () => {
  const response = await api.get(`${servicePrefix}/member/my-total`)
  return response.data
}

const nonActiveMembers = async () => {
  const response = await api.get(`${servicePrefix}/member/inactive-list-total`)
  return response.data
}

const MembershipService = {
  //membership
  getAllMember,
  getMemberById,
  addMember,
  registerMember,
  updateMember,
  deleteMember,
  searchMember,
  memberBulkUpload,
  getNewMemberCode,
  allMembers,
  totalMembersByMembership,
  totalMemberPending,
  getTotalMemberByCountry,
  getMemberTotal,
  totalMemberActive,
  nonActiveMembers
}

export default MembershipService
