// ** React Imports
import { lazy } from 'react'

import { Navigate } from 'react-router-dom'
const EditRole = lazy(() => import('../../views/apps/roles-permissions/roles/edit/index'))
const AddRole = lazy(() => import('../../views/apps/roles-permissions/roles/add/index'))

const RoleRoutes = [
  {
    element: <EditRole />,
    path: '/roles/view/:id/edit',
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Role'
    }
  },
  {
    element: <AddRole />,
    path: '/roles/add',
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Role'
    }
  }
]

export default RoleRoutes
