import { lazy } from 'react'

//membership
const MemberAdd = lazy(() => import('../../views/membership/member/add/index'))
const MemberBulkUpload = lazy(() => import('../../views/membership/member/bulk-upload/index'))
const MemberEdit = lazy(() => import('../../views/membership/member/edit/index'))
const MemberList = lazy(() => import('../../views/membership/member/list/index'))
const MemberView = lazy(() => import('../../views/membership/member/view/index'))
const MemberRegistration = lazy(() => import('../../views/membership/member/MemberRegistration'))
const NewMemberDetail = lazy(() => import('../../views/membership/member/NewMemberDetail'))
const Donation = lazy(() => import('../../views/membership/member/Donation'))
const RenewMembership = lazy(() => import('../../views/membership/member/RenewMembership'))
const RenewMembershipPayOnline = lazy(() => import('../../views/membership/member/RenewMembershipPayOnline'))
const RenewMemberSearch = lazy(() => import('../../views/membership/member/RenewMemberSearch'))
const RenewMembershipPaymentStatus = lazy(() => import('../../views/membership/member/RenewMembershipPaymentStatus'))

const Home = lazy(() => import('../../views/pages/home'))
const Faq = lazy(() => import('../../views/pages/faq'))

const MemberRoutes = [
  {
    path: '/register',
    element: <MemberRegistration />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/renew',
    element: <RenewMemberSearch />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/donate',
    element: <Donation />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // menuHidden: true
      // restricted: true
    }
  },
  {
    path: '/faq',
    element: <Faq />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
    }
  },
  {
    path: '/member/:id',
    element: <NewMemberDetail />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // restricted: true
    }
  },
  {
    path: '/member/:id/renew',
    element: <RenewMembership />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // restricted: true
    }
  },
  {
    path: '/member/:id/renew/:feeId/make-payment',
    element: <RenewMembershipPayOnline />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // restricted: true
    }
  },
  {
    path: '/member/:id/renew/:feeId/payment-status',
    element: <RenewMembershipPaymentStatus />,
    meta: {
      // layout: 'horizontal',
      publicRoute: true
      // restricted: true
    }
  },
  {
    path: '/membership/add',
    element: <MemberAdd />,
    meta: {
      layout: 'vertical',
      action: 'create',
      resource: 'Member'
    }
  },
  {
    path: '/members/bulk-upload-members',
    element: <MemberBulkUpload />,
    meta: {
      layout: 'vertical',
      action: 'create',
      resource: 'Member'
    }
  },
  {
    path: '/membership-list/edit/:id',
    element: <MemberEdit />,
    meta: {
      layout: 'vertical',
      action: 'update',
      resource: 'Member'
    }
  },
  {
    path: '/membership-list',
    element: <MemberList />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Member'
    }
  },
  {
    path: '/membership-list/view/:id',
    element: <MemberView />,
    meta: {
      layout: 'vertical',
      action: 'read',
      resource: 'Member'
    }
  }
]

export default MemberRoutes
