// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PermissionService from '@services/permission'
export const getPermissions = createAsyncThunk('permissions/getPermissions', async (params) => {
  const response = await PermissionService.getPermissions(params)
  return {
    total: response.meta.itemCount,
    params,
    data: response.data
  }
})

export const getAllPermissions = createAsyncThunk('permissions/getAllPermissions', async () => {
  const response = await PermissionService.getAllPermissions()
  return response
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allPermissions: [],
    selectedPermission: null
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selectedPermission = null
      } else {
        state.selectedPermission = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.total
        state.params = action.payload.params
        // state.allData = action.payload.allData
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.allPermissions = action.payload
      })
  }
})

export const { selectPermission } = permissionsSlice.actions

export default permissionsSlice.reducer
