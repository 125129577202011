// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RoleService from '@services/role'

export const getRoles = createAsyncThunk('roles/getRoles', async (params = {}) => {
  const response = await RoleService.getRoles(params)

  return {
    total: response.meta.itemCount,
    params,
    data: response.data
  }
})

export const getAllRoles = createAsyncThunk('roles/getAllRoles', async () => {
  const response = await RoleService.getAllRoles()
  return {
    allData: response.data
  }
})

export const getRole = createAsyncThunk('roles/getRole', async (id, { rejectWithValue }) => {
  try {
    return await RoleService.getRole(id)
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addRole = createAsyncThunk('roles/addRole', async (role, { rejectWithValue }) => {
  // await axios.post('/apps/users/add-user', user)
  try {
    const response = await RoleService.addRole(role)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateRole = createAsyncThunk('roles/updateRole', async (role, { rejectWithValue }) => {
  try {
    const response = await RoleService.updateRole(role.id, role)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    data: [],
    total: 0,
    params: {},
    allRoles: [],
    selectedRole: null,
    loading: false
  },
  reducers: {
    selectRole: (state, action) => {
      if (action.payload === null) {
        state.selectedRole = null
      } else {
        state.selectedRole = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.total
        state.params = action.payload.params
        // state.allData = action.payload.allData
      })
      // .addCase(getAllRoles.fulfilled, (state, action) => {
      //   state.allRoles = action.payload.allRoles
      // })
      // .addCase(getRole.fulfilled, (state, action) => {
      //   state.selectedRole = action.payload
      // })

      .addCase(getRole.fulfilled, (state, action) => {
        state.selectedRole = action.payload
        state.loading = false
      })
      .addCase(getRole.pending, (state, action) => {
        state.selectedRole = action.payload
        state.loading = true
      })
      .addCase(getRole.rejected, (state, action) => {
        state.selectedRole = action.payload
        state.loading = false
      })
  }
})

export const { selectRole } = rolesSlice.actions

export default rolesSlice.reducer
