// ** Icons Import
import { Heart } from 'react-feather'

const Footer = () => {
  return (
    <p className="clearfix mb-0">
      <span className="d-flex text-align-center justify-content-center">
        COPYRIGHT © {new Date().getFullYear()}
        <a href="" target="_blank" rel="noopener noreferrer">
          &nbsp; Kathok Dharma Center
        </a>
        <span className="d-none d-sm-inline-block">, All rights Reserved.</span>
      </span>
    </p>
  )
}

export default Footer
