// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PaymentFrequencyService from '@services/payment-frequency'

export const getPaymentFrequencies = createAsyncThunk('paymentFrequency/getPaymentFrequencies', async (params, { rejectWithValue }) => {
  try {
    const response = await PaymentFrequencyService.getPaymentFrequencies(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllPaymentFrequencies = createAsyncThunk('paymentFrequency/getAllPaymentFrequencies', async () => {
  const response = await PaymentFrequencyService.getAllCountries()
  return {
    allData: response.data
  }
})

export const getPaymentFrequencyById = createAsyncThunk('paymentFrequency/getPaymentFrequencyById', async (id) => {
  const response = await PaymentFrequencyService.getPaymentFrequencyById(id)
  return response
})

export const addPaymentFrequency = createAsyncThunk('paymentFrequency/addPaymentFrequency', async (paymentFrequency, { rejectWithValue }) => {
  try {
    const response = await PaymentFrequencyService.addPaymentFrequencies(paymentFrequency)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePaymentFrequency = createAsyncThunk('paymentFrequency/updatePaymentFrequency', async (paymentFrequency, { rejectWithValue }) => {
  try {
    const response = await PaymentFrequencyService.updatePaymentFrequencies(paymentFrequency)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deletePaymentFrequency = createAsyncThunk('paymentFrequency/deletePaymentFrequency', async (id, { dispatch, getState }) => {
  await PaymentFrequencyService.deletePaymentFrequencies(id)
  await dispatch(getPaymentFrequencies(getState()?.paymentFrequencies.params))
  return id
})

export const PaymentFrequencySlice = createSlice({
  name: 'PaymentFrequency',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allPaymentFrequencies: [],
    individualPaymentFrequency: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentFrequencies.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPaymentFrequencyById.fulfilled, (state, action) => {
        state.individualPaymentFrequency = action.payload
        state.loading = false
      })
      .addCase(getAllPaymentFrequencies.fulfilled, (state, action) => {
        state.allPaymentFrequencies = action.payload.allPaymentFrequencies
      })
      .addCase(getPaymentFrequencyById.pending, (state, action) => {
        state.individualPaymentFrequency = action.payload
        state.loading = true
      })
      .addCase(getPaymentFrequencyById.rejected, (state, action) => {
        state.individualPaymentFrequency = action.payload
        state.loading = false
      })
  }
})

export default PaymentFrequencySlice.reducer
