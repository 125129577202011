import { lazy } from 'react'

//country
const CountryList = lazy(() => import('../../views/master/country/list/index'))
const AddCountry = lazy(() => import('../../views/master/country/add/index'))
const EditCountry = lazy(() => import('../../views/master/country/edit/index'))

//membership-type
const MembershipTypeList = lazy(() => import('../../views/master/membership-type/list/index'))
const AddMembershipType = lazy(() => import('../../views/master/membership-type/add/index'))
const EditMembershipType = lazy(() => import('../../views/master/membership-type/edit/index'))

//payment frequency
const AddPaymentFrequency = lazy(() => import('../../views/master/payment-frequency/add/index'))
const PaymentFrequencyList = lazy(() => import('../../views/master/payment-frequency/list/index'))
const PaymentFrequencyEdit = lazy(() => import('../../views/master/payment-frequency/edit/index'))

const MasterRoutes = [
  //country
  {
    path: '/country',
    element: <CountryList />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },
  {
    path: '/country/add',
    element: <AddCountry />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },
  {
    path: '/country/edit/:id',
    element: <EditCountry />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },

  //membership-type
  {
    path: '/membership-type',
    element: <MembershipTypeList />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },
  {
    path: '/membership-type/add',
    element: <AddMembershipType />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },
  {
    path: '/membership-type/edit/:id',
    element: <EditMembershipType />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },

  //payment frequency
  {
    path: '/payment-frequency/add',
    element: <AddPaymentFrequency />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },
  {
    path: '/payment-frequency',
    element: <PaymentFrequencyList />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  },
  {
    path: '/payment-frequency/edit/:id',
    element: <PaymentFrequencyEdit />,
    meta: {
      layout: 'vertical',
      action: 'manage',
      resource: 'Master'
    }
  }
]

export default MasterRoutes
