import api from '@services'
import { stripEmptyObjects } from '@utils'

const servicePrefix = process.env.REACT_APP_API_BASE_URL

const getPermissions = async (params) => {
  const response = await api.get(`${servicePrefix}/permissions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllPermissions = async () => {
  const response = await api.get(`${servicePrefix}/permissions/all`)
  return response.data
}

const PermissionService = {
  getPermissions,
  getAllPermissions
}

export default PermissionService
