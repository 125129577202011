import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

const getAllDonation = async (params) => {
  const response = await api.get(`${servicePrefix}/donations`, { params: stripEmptyObjects(params) })
  return response.data
}

const allDonation = async () => {
  const response = await api.get(`${servicePrefix}/donations/all`)
  return response.data
}

const getAllPendingDonation = async () => {
  const response = await api.get(`${servicePrefix}/donations/pending-list`)
  return response.data
}

const getDonationById = async (id) => {
  const response = await api.get(`${servicePrefix}/donations/${id}`)
  return response.data
}
const getDonationByMemberId = async (memberId) => {
  const response = await api.get(`${servicePrefix}/donations/get-memberShip-donation/${memberId}`)
  // console.log('service', response)
  return response.data
}

const addDonation = async (donation) => {
  const response = await api.post(`${servicePrefix}/donations`, donation)
  return response.data
}

const addMobileTransfer = async (donation) => {
  const response = await api.post(`${servicePrefix}/donations/mobile-transfer`, donation, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

const addWordlineDonation = async (donation) => {
  const response = await api.post(`${servicePrefix}/donations/create-worldline-donation`, donation)

  return response.data
}

const updateDonationPayment = async (donation) => {
  const id = donation.get('id')

  const response = await api.patch(`${servicePrefix}/donations/${id}/update-donation-payment`, donation, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

const paymentBulkUpload = async (data) => {
  const response = await api.post(`${servicePrefix}/donations/bulk-upload-payments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const updateDonation = async (donation) => {
  console.log(donation)
  const { id } = donation
  const response = await api.patch(`${servicePrefix}/donations/${id}`, donation)
  return response.data
}

const deleteDonation = async (id) => {
  const response = await api.delete(`${servicePrefix}/donations/${id}`)
  return response.data
}

const getTaxReportByCid = async (id, date, currency) => {
  const response = await api.get(`${servicePrefix}/donations/tax-report/${id}/${date}/${currency}`)
  return response.data
}

const DonationService = {
  //DonationService
  getAllDonation,
  getAllPendingDonation,
  getDonationById,
  getDonationByMemberId,
  addDonation,
  addMobileTransfer,
  addWordlineDonation,
  updateDonation,
  deleteDonation,
  updateDonationPayment,
  allDonation,
  paymentBulkUpload,
  getTaxReportByCid
}

export default DonationService
